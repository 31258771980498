<template>
  <v-container fluid ma-0 pa-0>
    <v-card>
        <v-card-title>
          Perfil actual.
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" color="primary">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
              <v-list>
                <v-list-item link @click="editMode = 'pass'">
                  <v-list-item-title>Cambiar contraseña</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-key-arrow-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item link @click="editMode = 'edit'">
                  <v-list-item-title>Editar Perfil</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-account-edit</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <!-- <v-list-item link color="indigo">
                  <v-list-item-title>Cerrar sesión</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-list-item-icon>
                </v-list-item> -->
              </v-list>
            </v-menu>
        </v-card-title>
        <v-card-subtitle>
          Algun texto.
        </v-card-subtitle>
        <!-- // if info -->
        <v-card-text v-if="editMode == 'info'">
          <p>
            <span class="font-weight-black">Nombre: </span> {{ editCurrentUser.displayName }} <br>
            <span class="font-weight-black">E-mail:</span> {{ editCurrentUser.email }} <br>
            <span class="font-weight-black">ID:</span> {{ editCurrentUser.uid }} <br>
            <span class="font-weight-black">Admin:</span> {{ editCurrentUser.photoURL === 'true' ? 'Sí' : 'No' }}
          </p>
        </v-card-text>
        <!-- // if pass-->
        <v-card-text v-else-if="editMode == 'pass'">
            <v-row>
              <v-col>
                <p class="font-weight-medium primary--text">Recibiras un enlance para canbiar tu contraseña a {{ editCurrentUser.email }}</p>
                <v-btn block elevation="0" color="primary" @click="resetPassword()">Enviar correo <v-icon class="ml-2">mdi-email</v-icon></v-btn>
              </v-col>
            </v-row>
        </v-card-text>
                <!-- //  if edit-->
        <v-card-text v-else-if="editMode == 'edit'">
          <v-form @submit.prevent="updateUser()" ref="form" id="updateUser-form" v-on:submit.prevent lazy-validation>
            <v-row>
              <v-col>
                <v-text-field label="Nombre" v-model="editCurrentUser.displayName" :rules="nameRules"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="E-mail" v-model="editCurrentUser.email" :rules="emailRules" disabled></v-text-field>
              </v-col>
              <v-col>
                <!-- <v-text-field label="Nueva contraseña" v-model="editCurrentUser.password" counter :rules="passRules"></v-text-field> -->
              </v-col>
            </v-row>
            <v-card-actions>
            <v-row dense justify="end">
              <v-col>
                <v-switch v-model="editCurrentUser.photoURL" label="Admin" color="primary" false-value="false" true-value="true" hide-details></v-switch>
              </v-col>
              <v-col>
                <v-btn text type="submit" color="primary" form="updateUser-form" @click="editMode = 'info'">cancelar</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn block type="submit" color="primary" form="updateUser-form">Guardar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          </v-form>
        </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
// import 'firebase/compat/firestore';

import { mapActions } from 'vuex'

export default {
  name: 'CurrentUser',

  data: () => ({
    editMode: 'info',
    editCurrentUser: [],
    nameRules: [
      v => !!v || 'Campo requerido',
      v => (v && v.length >= 3) || 'Minimo 3 caracteres',
    ],
    emailRules: [
      v => !!v || 'E-mail requerido',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail no es valido',
    ],
    passRules: [
      v => !!v || 'Contraseña requerida',
      v => (v && v.length >= 12) || 'Minimo 12 caracteres',
    ],
    snackbarData: {
      title: '😀🤘',
      text: 'Los cambios se realizaron con éxito.',
      icon: 'mdi-check-circle',
      color: 'success',
      timeout: 5000,
      mode: "multi-line",
    },
    //
  }),
   created() {
    this.getCurrentUsers()
  },
  methods: {
    getCurrentUsers() {
      const user = firebase.auth().currentUser;
      if (user !== null) {
        this.editCurrentUser.email = user.email,
        this.editCurrentUser.displayName = user.displayName,
        this.editCurrentUser.uid = user.uid,
        this.editCurrentUser.photoURL = user.photoURL
        // console.log(this.editCurrentUser)
      } else {
        console.log('no tengo user')
      }
    },
    //
    updateUser(e) {
      if (this.$refs.form.validate()) {
        const user = firebase.auth().currentUser
        user.updateProfile({
          displayName: this.editCurrentUser.displayName,
          photoURL: this.editCurrentUser.photoURL,
        }).then(() => {
          console.log(this.editCurrentUser),
          this.getCurrentUsers()
          this.showSnack({
           title: '😀🤘',
            text: 'Los cambios se realizaron con éxito.',
            icon: 'mdi-check-circle',
            color: 'success',
          })
          this.editMode = 'info'
        }).catch(function(error) {
          console.log(error)
        })
       }
       e.preventDefault();
    },
    //
    resetPassword() {
      const auth = getAuth()
      sendPasswordResetEmail(auth, this.editCurrentUser.email)
      .then(() => {
        this.showSnack({
          title: '😎👍',
          text: 'En breve recibirás un correo para cambiar tu contraseña.',
          icon: 'mdi-check-circle',
          color: 'success',
        })
        this.editMode = 'info'
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        console.log(error.message)
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // ..
      })
    },

      ...mapActions('snackbar', ['showSnack']),
    saveDetails(data) {
      this.showSnack(data)
    },


  },
  //
}
</script>
