<template>
  <v-container fluid ma-0 pa-0>
    <v-card>
      <v-form @submit.prevent="createNewUser()" ref="form" id="createUser-form" lazy-validation>
        <v-card-title>
          Agregar usuaria(o).
        </v-card-title>
        <v-card-subtitle>
          para acceder a este panel.
        </v-card-subtitle>
        <v-card-text>
          <v-row dense no-gutters>
            <v-col cols="12">
              <v-text-field label="Nombre" v-model="createNewUserData.name" :rules="nameRules"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="E-mail" v-model="createNewUserData.email" :rules="emailRules"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Contraseña" v-model="createNewUserData.password" counter :rules="passRules"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col>
              <v-switch v-model="createNewUserData.isadmin" label="Admin" color="primary" false-value="false" true-value="true" hide-details class="mt-0 pt-0"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-btn block type="submit" color="primary" form="createUser-form">Guardar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>

    <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color" top :multi-line="snackbar.mode === 'multi-line'">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ snackbar.title }}</strong>
          </div>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
      <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar.active = false" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import firebaseConfig from '@/plugins/firebase.js'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

export default {
  name: 'CreateUser',

  data: () => ({
    createNewUserData: {
      name: '',
      email: '',
      password: '',
      isadmin: 'false'
    },
    nameRules: [
      v => !!v || 'Campo requerido',
      v => (v && v.length >= 3) || 'Minimo 3 caracteres',
    ],
    emailRules: [
      v => !!v || 'E-mail requerido',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail no es valido',
    ],
    passRules: [
      v => !!v || 'Contraseña requerida',
      v => (v && v.length >= 12) || 'Minimo 12 caracteres',
    ],
    snackbar: {
      active: false,
      timeout: 5000,
      color: '',
      title: '',
      text: '',
      icon: '',
      mode: "multi-line",
    }
  }),
  methods: {
    createNewUser() {
    if (this.$refs.form.validate()) {
      var secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary")
        secondaryApp.auth().createUserWithEmailAndPassword(this.createNewUserData.email, this.createNewUserData.password)
        // eslint-disable-next-line no-unused-vars
        .then((userRecord) => {
          secondaryApp.auth().currentUser.updateProfile({
              displayName: this.createNewUserData.name,
              photoURL: this.createNewUserData.isadmin,
            }).then(() => {
              this.snackbar.color = 'success'
              this.snackbar.title = 'Exito'
              this.snackbar.icon = 'mdi-alert-circle'
              this.snackbar.text = 'Usuaria(o) se egrego con exito.'
              this.snackbar.active = true
              this.createNewUserData.name = '',
              this.createNewUserData.email = '',
              this.createNewUserData.password = ''
              this.createNewUserData.isadmin = 0
              this.$refs.form.reset()
            })
          secondaryApp.auth().signOut();
        })
        .catch((error) => {
          this.snackbar.color = 'error'
          this.snackbar.title = 'Error'
          this.snackbar.icon = 'mdi-alert-circle'
          this.snackbar.text = error.message
          this.snackbar.active = true
        })
      }
    }
    //
  },
}
</script>