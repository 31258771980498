<template>
  <v-container fluid ma-0 pa-0>
    <v-card disabled>
      <v-card-title>
        Listado de administradora(e)s.
      </v-card-title>
      <v-card-subtitle>
        Algun texto.
      </v-card-subtitle>
      <v-card-text>
        Acaba esto Phi <v-icon>mdi-jellyfish</v-icon><v-icon>mdi-penguin</v-icon>
      </v-card-text>
      <v-card-actions>
        <v-btn>
          Accion
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'ListUsures',

  data: () => ({
    //
  }),
  methods: {
    //
  },
};
</script>