<template>
  <v-container fluid>
    <!-- <h1>Usuarios</h1> -->
    <v-row>
      <v-col>
        <v-row dense>
          <v-col cols="12" class="mb-5">
            <CurrentUser />
          </v-col>
          <v-col cols="12">
            <CreateUser />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <ListUsures />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CurrentUser from '@/components/users/CurrentUser'
import CreateUser from '@/components/users/CreateUser'
import ListUsures from '@/components/users/ListUsers'

export default {
  name: 'Usuarios',

  data: () => ({
    //
  }),
  created() {
  //
  },
  methods: {
    //
  },
  components: {
    CurrentUser,
    CreateUser,
    ListUsures, 
  }
};
</script>